<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <h2 class="text-xl font-bold">
        <img src="@/assets/images/icons8-people-96.png" class="h-20 ml-2 inline-block" />
        اطلاعات کانال
      </h2>
      <h2 class="text-xl font-bold">
        <Icon name="Group" />
        {{ channel.name }}
      </h2>
      <div class="mt-3 text-right" dir="ltr">{{ channel.cid }}</div>
    </template>
    <template #default>
      <div class="grid sm:grid-cols-2 gap-3">
        <div class="p-3 bg-blueGray-100 border border-blueGray-200 rounded-md">
          <div class="text-lg font-bold">
            <img
              src="@/assets/images/icons8-membership-card-96.png"
              class="inline-block h-20"
            />
            خرید اشتراک در این کانال
          </div>
          <p class="mt-5">
            میتوانید اشتراک این کانال را خرید کنید تا بتوانید وارد آن شوید
          </p>
          <div class="grid sm:grid-cols-3">
            <Button
              :to="{
                name: 'ChannelUserAccounts',
                params: { id: $route.params.id, channelId: $route.params.channelId },
              }"
              class="mt-3 inline-flex text-lg"
              color="cyan"
            >
              خرید اشتراک
            </Button>
          </div>
        </div>
        <div class="p-3 bg-blueGray-100 border border-blueGray-200 rounded-md">
          <div class="text-lg font-bold">
            <img
              src="@/assets/images/1054990_rocket_spacecraft_spaceship_icon.png"
              class="inline-block h-20"
            />
            سفارش تبلیغات در این کانال
          </div>
          <p class="mt-5">میتوانید در این گروه به طور خودکار تبلیغات سفارش دهید</p>
          <div class="grid sm:grid-cols-3">به زودی ...</div>
        </div>
      </div>
      <div
        class="p-3 bg-blueGray-100 border border-blueGray-200 rounded-md mt-5"
        v-if="channel?.links?.[0]?.invite_link"
      >
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-link-96.png" class="inline-block h-20" />
          لینک کانال
        </div>
        <p v-if="channel?.links?.[0]?.name == 'vip_link'">
          این کانال VIP است ابتدا باید اشتراک خرید کرده تا بتوانید وارد کانال شوید.
        </p>
        <div class="sm:w-1/3 mx-auto mt-5">
          <Button :href="channel?.links?.[0]?.invite_link" target="_blank">
            <template v-if="channel?.links?.[0]?.name == 'vip_link'">
              درخواست عضویت در کانال
            </template>
          </Button>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";
import Button from "@/components/Button.vue";

export default {
  components: {
    BodySimple,
    Icon,
    Button,
  },
  data() {
    return {
      firstLoading: true,
      channel: {},
      admins: [],
      // L_: _,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let $this = this;
      // console.log($this.$store.state.user.id);
      $this.$axios
        .get("api/user-channels/" + $this.$route.params.channelId, {
          params: {
            id: $this.$store.state.user.id,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.channel = response.data.channel;
        })
        .catch(function (error) {
          // console.log(error);
        })
        .finally(function () {
          // always executed
          $this.firstLoading = false;
        });
    },
  },
};
</script>

<style></style>
